import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import languages from 'lang/compiled-lang';

let cachedIntl: IntlShape;

let cachedLocale: string;

export const useIntl = (locale: string): IntlShape => {
  const cache = createIntlCache();

  if (cachedLocale === locale) return cachedIntl;

  cachedLocale = locale;
  const intl = createIntl(
    {
      locale,
      messages: languages[locale],
    },
    cache,
  );

  cachedIntl = intl;

  return intl;
};
