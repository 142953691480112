import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// CSS variables
const cssVars = {
  colorWhite: '#FFF',
  colorBlack: '#000',
  colorTransparent: 'rgba(0, 0, 0, 0)',

  color01: '#0079FF',
  color02: '#C5C5C5',
  color03: '#F5F6F9',
  color04: '#B7BED5',
  color05: '#1F2041',
  color06: '#FF5D49',
  color07: '#30CB6A',
  color08: '#828282',
  color09: '#CCCCCC',
  color10: '#5F7287',
  color11: '#74B4FA',
  color12: '#FF0000',
  color13: '#EEEEEE',
  color14: '#939393',
  color15: '#FFBF13',
  color16: '#0000FF',
  color17: '#8B0000',
  color18: '#808080',
  color19: '#DDDDDD',
  color20: '#777777',
  color21: '#0C7FFF',
  color22: '#B7E9ED',
  color23: '#ADDDE1',
  color24: '#6B6B76',
  color25: '#B7FF6E',
  color26: '#FE7B79',
  color27: '#7AC779',
  color28: '#7C7C93',
  color29: '#F5A1DB',
  color30: '#FDE774',
  color31: '#7BA97A',
  color32: '#BEBDFE',
  color33: '#C5A3D5',
  color34: '#BDE490',
  color35: '#7ABEC8',
  color36: '#7C7ABE',
  color37: '#F8B881',
  color38: '#CADCFD',
  color39: '#BA91B3',
  color40: '#D8D8D8',
  color41: '#90D894',
  color42: '#EDED1A',
  color43: '#FBD519',
  color44: '#97DA9A',
  color45: '#79B2E2',
  color46: '#D2D2D2',
  color47: '#F5F5F5',
  color48: '#CED5E8',
  color49: '#3498db',
  color50: '#0A72E6',
  color51: '#A6D0FF',
  color52: '#75B6FF',
  color53: '#057bff',
  color54: '#d7eaff',
  color55: '#00AB40',
  color56: '#FFEC31',
  color57: '#FFAA31',
  color58: '#1BB655',
  color59: '#54E58A',
  color60: '#F1F1F1',
  color61: '#959595',
  color62: '#FF00FF',

  color101: 'rgba(0, 0, 0, 0.15)',
  color102: 'rgba(0, 0, 0, 0.2)',
  color103: 'rgba(0, 0, 0, 0.3)',
  color104: 'rgba(0, 0, 0, 0.7)',
  color105: 'rgba(0, 0, 0, 0.14)',
  color106: 'rgba(0, 0, 0, 0.12)',
  color107: 'rgba(0, 0, 0, 0.8)',
  color108: 'rgba(130, 130, 130, 0.87)',
  color109: 'rgba(0, 0, 0, 0.75)',
  color110: 'rgba(197, 197, 197, 0.5)',
  color111: 'rgba(0, 0, 0, 0.168)',
  color112: 'rgba(0, 0, 0, 0.87)',
  color113: 'rgba(73, 73, 73, 0.87)',
  color115: 'rgba(0, 0, 0, 0.25)',
  color116: 'rgba(0, 0, 0, 0.1)',
  color117: 'rgba(0, 121, 255, 0.87)',
  color118: 'rgba(0, 0, 0, 0.5)',
  color119: 'rgba(0, 121, 255, 0.1)',
};

Object.entries(cssVars).forEach(([name, value]) => {
  document.documentElement.style.setProperty(`--${name}`, value);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const theme: any = createMuiTheme({
  ...cssVars,

  palette: {
    primary: {
      main: cssVars.color01,
    },
    secondary: {
      main: cssVars.color02,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: cssVars.colorWhite,
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
  },
  overrides: {
    MuiSelect: {
      icon: {
        color: cssVars.color01,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: cssVars.colorWhite,
        color: cssVars.colorBlack,
      },
      popper: {
        zIndex: 10000,
      },
    },
    MuiPopover: {
      root: {
        zIndex: '10000 !important' as never,
      },
    },
  },
});

export default theme;
