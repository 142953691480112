import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { TenantSelectProps } from 'interfaces';
import styles from './TenantSelect.module.scss';

export const tenantNames: { [key: string]: string } = {
  '20daceeb-7a05-47db-944e-4bb42cff60f7': 'Tel Aviv Demo (axilionCSP.onmicrosoft.com)',
  '9afd522f-56aa-4b54-8a26-cc56773d60fb': 'Boston',
  'a0ef4bae-3886-4687-a672-03b9a93d5d87': 'Test organization (axilionTEST.onmicrosoft.com)',
  'aa640f10-95f8-4f05-96f1-529dbbc11897': 'Tel Aviv Municipality (Client tenant)',
  'ce6a8964-5bcc-4ed2-88f5-04034f04c923': 'Axilion (axilionDEV.onmicrosoft.com)',
  'c464fb65-7f37-4274-84bf-0ccc8f4d937f': 'New York',
  '4c15c98f-5e6f-4b9c-abe0-8590ed61dc5a': 'Jerusalem',
  'b6154596-da83-4670-948f-6b39a948e9a1': 'Bolzano (Stage)',
  '3c673c8b-29d8-4c42-929f-a346a66a881f': 'Bolzano (Client Tenant)',
  '44c25cb8-ff50-405c-9368-6f5304e02a7b': 'QA Tenant',
};

export const TenantSelect: React.FC<TenantSelectProps> = ({
  tenantIds,
  currentTenantId,
  onSelect,
  formControlProps,
  selectProps,
  menuItemProps,
  inputLabelProps,
}) => {
  /* ---------------------------------- Hooks --------------------------------- */

  /* ---------------------------------- State --------------------------------- */
  const [selectedTenantId, setSelectedTenantId] = useState(currentTenantId || '');

  /* -------------------------------- Variables ------------------------------- */
  const tenants = Object.entries(tenantNames).filter(([id]) => tenantIds.includes(id));

  /* -------------------------------- Functions ------------------------------- */
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const tenantId = event.target.value as string;
    setSelectedTenantId(tenantId);
    if (onSelect) {
      onSelect(tenantId);
    }
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <FormControl size="small" variant="outlined" {...formControlProps}>
      <InputLabel {...inputLabelProps}>Tenant</InputLabel>
      <Select
        label="Tenant ID"
        value={selectedTenantId}
        onChange={handleChange}
        MenuProps={{ className: styles.select }}
        variant="outlined"
        {...selectProps}
      >
        {tenants.map(([id, name]) => (
          <MenuItem key={id} value={id} {...menuItemProps}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
