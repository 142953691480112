import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { LoadingOverlayProps } from 'interfaces';
import styles from './LoadingOverlay.module.scss';

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ opacity = 0.7, zIndex = 9999, progressProps = {} }) => {
  /* ---------------------------------- Hooks --------------------------------- */

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  if (!progressProps.size) {
    progressProps.size = 60;
  }

  if (!progressProps.thickness) {
    progressProps.thickness = Number(progressProps.size) / 12;
  }

  /* -------------------------------- Functions ------------------------------- */

  /* --------------------------------- Effects -------------------------------- */

  return (
    <div
      className={styles.overlay}
      style={{
        zIndex,
        background: `rgba(0, 0, 0, ${opacity})`,
      }}
    >
      <CircularProgress {...progressProps} />
    </div>
  );
};
