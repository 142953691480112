const requireModule = require.context('.', false, /\.json$/);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const languages = {} as any;

requireModule.keys().forEach((fileName) => {
  const languageName = fileName.replace(/(\.\/|\.json)/g, '');

  languages[languageName] = requireModule(fileName);
});

export default languages;
