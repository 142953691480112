import React from 'react';
import { XYPlot, ArcSeries } from 'react-vis';
import { RadialChartProps } from 'interfaces';
import styles from './RadialChart.module.scss';

export const RadialChart: React.FC<RadialChartProps> = ({ arcsData, arcsTitles, scale }) => {
  return (
    <div className={styles.root}>
      <div className={styles.titles}>
        {arcsTitles.map((title) => (
          <div className={styles.title} key={title}>
            {title}
          </div>
        ))}
      </div>
      {scale.map(({ degrees, label }) => (
        <div
          className={styles.scaleItem}
          key={label}
          style={{
            transform: `rotate(${degrees}deg)`,
            clipPath: `inset(-30px -30px -30px ${120 - arcsTitles.length * 21}px)`,
          }}
        >
          <div className={styles.scaleItemLabel} style={{ transform: `translateY(-50%) rotate(${degrees * -1}deg` }}>
            {label}
          </div>
        </div>
      ))}
      <XYPlot className={styles.XYPlot} xDomain={[-1, 1.25]} yDomain={[-2.6, 3]} width={300} height={300}>
        <ArcSeries colorType="literal" radiusDomain={[0, 3]} data={arcsData} />
      </XYPlot>
    </div>
  );
};
