import { IntlShape } from 'react-intl';
import { DetectionType } from 'interfaces';
import theme from 'theme';
import { BoundingBoxType } from 'enums';

export const getDetectionTypes = (intl: IntlShape): Map<BoundingBoxType, DetectionType> => {
  const detectionTypes = new Map();

  detectionTypes.set(BoundingBoxType.PERSON, {
    text: intl.formatMessage({
      defaultMessage: 'Pedestrian Detection',
      description: 'Detection type',
    }),
    color: theme.color25,
  });
  detectionTypes.set(BoundingBoxType.BICYCLE, {
    text: intl.formatMessage({
      defaultMessage: 'Cyclist Detection',
      description: 'Detection type',
    }),
    color: theme.color57,
  });
  detectionTypes.set(BoundingBoxType.FACE_MASK_USAGE, {
    text: intl.formatMessage({
      defaultMessage: 'Face Mask Worn',
      description: 'Detection type',
    }),
    color: theme.color25,
  });
  detectionTypes.set(BoundingBoxType.NO_FACE_MASK_USAGE, {
    text: intl.formatMessage({
      defaultMessage: 'Face Mask Not Worn',
      description: 'Detection type',
    }),
    color: theme.color26,
  });
  detectionTypes.set(BoundingBoxType.SCOOTER, {
    text: intl.formatMessage({
      defaultMessage: 'Scooter Detection',
      description: 'Detection type',
    }),
    color: theme.color42,
  });
  detectionTypes.set(BoundingBoxType.MOTORBIKE, {
    text: intl.formatMessage({
      defaultMessage: 'Motorbike Detection',
      description: 'Detection type',
    }),
    color: theme.color53,
  });
  detectionTypes.set(BoundingBoxType.REFUGE_ISLAND, {
    text: intl.formatMessage({
      defaultMessage: 'Crowded Refuge Island Detected',
      description: 'Detection type',
    }),
    color: theme.color62,
  });

  return detectionTypes;
};
