import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ListMenuProps } from 'interfaces';
import { Tooltip } from '@material-ui/core';
import styles from './ListMenu.module.scss';

export const ListMenu: React.FC<ListMenuProps> = ({
  items,
  onSelect,
  initialItem,
  type = 'basic',
  colorScheme = 'default',
  className = '',
}) => {
  /* ---------------------------------- Hooks --------------------------------- */
  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const [activeItem, setActiveItem] = useState<typeof items[0] | null>(null);

  /* -------------------------------- Functions ------------------------------- */
  const handleClick = (item: typeof items[0]) => {
    if (!item.disabled) {
      setActiveItem(item);
    }
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (activeItem) {
      onSelect(activeItem);
    }
  }, [activeItem]); //eslint-disable-line

  useLayoutEffect(() => {
    if (initialItem !== activeItem) {
      setActiveItem(initialItem || items[0]);
    }
  }, [items, initialItem]); //eslint-disable-line

  return (
    <div
      className={`
        ${className}
        ${styles[`type-${type}`]}
        ${colorScheme ? styles[`colorScheme-${colorScheme}`] : ''}
      `}
    >
      {items.map(({ name, text, icon, tooltip, disabled = false, nested = false }, index) => (
        <Tooltip
          title={tooltip || ''}
          placement="top"
          key={name}
          disableHoverListener={!tooltip}
          disableFocusListener={!tooltip}
          disableTouchListener={!tooltip}
        >
          <button
            className={`
              ${styles.item}
              ${items[index] === activeItem ? styles.active : ''}
              ${nested ? styles.nested : ''}
            `}
            type="button"
            disabled={disabled}
            onClick={() => handleClick(items[index])}
          >
            {icon && <span className={styles.iconBox}>{icon}</span>}
            {text && <span className={styles.text}>{text}</span>}
          </button>
        </Tooltip>
      ))}
    </div>
  );
};
