import { Redirect, Route } from 'react-router-dom';
import { ProtectedRouteProps } from 'interfaces';

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  publicRoute,
  isAuthorized,
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const render = (props: any) => {
    if (isAuthorized) {
      return <Component {...props} />;
    }

    return <Redirect to={{ pathname: publicRoute }} />;
  };

  return <Route {...rest} render={render} />;
};
