import { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import { CircularProgress, Modal, Backdrop, Fade } from '@material-ui/core';
import {
  PlayCircleFilled,
  Pause,
  PlayArrow,
  FastForward,
  Fullscreen,
  FullscreenExit,
  InfoOutlined,
} from '@material-ui/icons';
import { FramesSliderProps } from 'interfaces';
import { ReactComponent as VideoIcon } from 'assets/images/video-icon.svg';
import { FrameCanvas } from './FrameCanvas/FrameCanvas';
import { preloadImage } from './preloadImage';
import imageNotAvailable from './Image not available.@2x.png';
import styles from './FramesSlider.module.scss';

export const FramesSlider: React.FC<FramesSliderProps> = ({
  framesLists,
  fromUtcToProjectTime,
  frameTimePosition = 'right',
  listIndex: _listIndex = 0,
  showFrameDetails,
  onListIndexChange,
  isLoading: isLoadingFrames,
  showInfoButton,
  showBoxes,
  showTags,
  showNotFound,
  fillParentHeight,
  overlayContent,
  bottomRightContent,
  showCountInLegend,
  onActiveFrameCoordsChange,
  onActiveFrameDetailsChange,
  onInfoClick,
  metricType,
}) => {
  /* ---------------------------------- Refs ---------------------------------- */
  const rootElementRef = useRef<HTMLDivElement>(null);
  const playPauseButtonRef = useRef<HTMLButtonElement>(null);
  const nextFrameButtonRef = useRef<HTMLButtonElement>(null);
  const prevFrameButtonRef = useRef<HTMLButtonElement>(null);
  const frameImgRef = useRef<HTMLImageElement>(null);
  const showFrameSpinnerTimeout = useRef<NodeJS.Timeout | null>(null);
  const nextFrameTimeout = useRef<NodeJS.Timeout | null>(null);

  /* ---------------------------------- State --------------------------------- */
  const [frameTimeDetails, setFrameTimeDetails] = useState<{
    date: string;
    time: string;
  }>({ date: '', time: '' });
  const [frameLoadError, setFrameLoadError] = useState(false);
  const [showFrameSpinner, setShowFrameSpinner] = useState(false);
  const [frameIsLoaded, setFrameIsLoaded] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [listIndex, setListIndex] = useState(_listIndex);
  const [frameIndex, setFrameIndex] = useState(0);

  /* -------------------------------- Variables ------------------------------- */
  const showOverlayContent = overlayContent && !isLoadingFrames;
  const notFound = frameLoadError || showNotFound;
  const frames = useMemo(() => framesLists[listIndex] || [], [framesLists, listIndex]);

  /* -------------------------------- Functions ------------------------------- */
  const nextListIndex = (current: number) => (current >= framesLists.length - 1 ? 0 : current + 1);

  const prevListIndex = (current: number) => (current === 0 ? framesLists.length - 1 : current - 1);

  const forwardFrameIndex = (current: number, step = 1) => {
    if (step >= frames.length) {
      return current;
    }

    return current + step <= frames.length - 1 ? current + step : current + step - frames.length;
  };

  const backwardFrameIndex = (current: number, step = 1) => {
    if (step >= frames.length) {
      return current;
    }

    return current - step >= 0 ? current - step : current - step + frames.length;
  };

  const handleTogglePlayClick = () => {
    setIsPlaying((state) => !state);
  };

  const handleNextFrameClick = () => {
    setFrameIndex((prevState) => forwardFrameIndex(prevState));
  };

  const skipForward = () => {
    setFrameIndex((prevState) => forwardFrameIndex(prevState, 10));
  };

  const skipBackward = () => {
    setFrameIndex((prevState) => backwardFrameIndex(prevState, 10));
  };

  const handlePrevFrameClick = () => {
    setFrameIndex((prevState) => backwardFrameIndex(prevState));
  };

  const handleNextListClick = () => {
    setFrameIndex(0);
    setListIndex(nextListIndex(listIndex));
  };

  const handlePrevListClick = () => {
    setFrameIndex(0);
    setListIndex(prevListIndex(listIndex));
  };

  const onFrameLoad = () => {
    setFrameIsLoaded(true);
    setFrameLoadError(false);
  };

  const onFrameLoadError = () => {
    setFrameIsLoaded(true);
    setFrameLoadError(true);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case 'ArrowRight':
        nextFrameButtonRef?.current?.click();
        break;
      case 'ArrowLeft':
        prevFrameButtonRef?.current?.click();
        break;
      case 'ArrowUp':
        skipForward();
        break;
      case 'ArrowDown':
        skipBackward();
        break;
      case 'Space':
        playPauseButtonRef?.current?.click();
        break;
      default:
    }
  };

  const handleOnClickInfoFrame = () => {
    if (onInfoClick) onInfoClick();
  };

  const handleFullScreenModalClose = () => {
    setFullScreen(false);
  };

  const handleFullScreenModalOpen = () => {
    setFullScreen(true);
  };

  const FrameTime = () => (
    <div className={`${styles.frameTime} ${styles.dataBox}`} data-testid="frameTime">
      <p>{frameTimeDetails.date}</p>
      <p>{frameTimeDetails.time}</p>
    </div>
  );

  const Slider = () => (
    <div
      className={`
        ${styles.slider}
        ${fillParentHeight && !fullScreen ? styles.fillParent : ''}
        ${fullScreen ? styles.fullScreen : ''}
        ${frameLoadError ? styles.frameLoadError : ''}
        ${showOverlayContent ? styles.overlayContentVisible : ''}
        ${isLoadingFrames || showFrameSpinner ? styles.isLoading : ''}
        ${notFound ? styles.notFound : ''}
      `}
      ref={rootElementRef}
    >
      {showOverlayContent && <div className={styles.overlayContent}>{overlayContent}</div>}
      {!frames.length || (
        <>
          <img
            className={styles.frameImage}
            alt="Video frame"
            src={frames[frameIndex]?.frameUrl}
            onLoad={onFrameLoad}
            onError={onFrameLoadError}
            data-testid="frameImage"
            ref={frameImgRef}
          />
          {showBoxes && frameIsLoaded && !overlayContent && (
            <div className={styles.frameCanvas}>
              <FrameCanvas
                boundingBoxes={frames[frameIndex].boundingBoxes}
                tags={frames[frameIndex].tags}
                imgRef={frameImgRef}
                showCountInLegend={showCountInLegend}
                metricType={metricType}
              />
            </div>
          )}
        </>
      )}
      {(isLoadingFrames || !frames.length || notFound) && (
        <div className={styles.iconsOverlay}>
          {isLoadingFrames && !showNotFound && (
            <CircularProgress size={26} thickness={4} data-testid="framesLoadingIndicator" />
          )}
          {!frames.length && !isLoadingFrames && !notFound && (
            <VideoIcon className="icon-wrapper" data-testid="noFramesIcon" />
          )}
          {notFound && (
            <img
              src={imageNotAvailable}
              alt="Not available"
              data-testid="imageNotAvailable"
              className={styles.imageNotAvailable}
            />
          )}
        </div>
      )}
      <div className={styles.toolsOverlay}>
        {showFrameSpinner && <CircularProgress className={styles.imageFrameLoadingIndicator} size={26} thickness={4} />}
        <div className={styles.topBar}>
          <div className={styles.leftCol}>
            {showFrameDetails && framesLists.length && fullScreen && (
              <div className={`${styles.dataBox} ${styles.darkBox}`} data-testid="listNumber">
                {`${listIndex + 1} / ${framesLists.length}`}
              </div>
            )}
            {showFrameDetails && frames.length && (
              <div className={`${styles.frameCounter} ${styles.dataBox}`} data-testid="frameNumber">
                {`${frameIndex + 1} / ${frames.length}`}
              </div>
            )}
          </div>
          <div className={styles.rightCol}>
            {showInfoButton && (
              <button
                type="button"
                className={`${styles.infoButton} ${styles.dataBox}`}
                data-testid="infoButton"
                onClick={handleOnClickInfoFrame}
              >
                <InfoOutlined />
              </button>
            )}
            {fullScreen ? (
              <button
                type="button"
                className={`${styles.fullScreenButton} ${styles.dataBox}`}
                data-testid="exitFullScreenButton"
                onClick={handleFullScreenModalClose}
              >
                <FullscreenExit />
              </button>
            ) : (
              !notFound && (
                <button
                  type="button"
                  className={`${styles.fullScreenButton} ${styles.dataBox}`}
                  data-testid="enterFullScreenButton"
                  onClick={handleFullScreenModalOpen}
                >
                  <Fullscreen />
                </button>
              )
            )}
          </div>
        </div>
        <div className={styles.mainBar}>
          {showTags && fullScreen && (
            <div className={`${styles.tags} ${styles.dataBox}`}>
              {frames[frameIndex]?.tags.map((tag: string) => (
                <span className={styles.tag} key={tag}>
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={styles.bottomBar}>
          <div className={`${styles.col} ${styles.firstcol}`}>
            {showFrameDetails && !notFound && frameTimePosition === 'left' && <FrameTime />}
          </div>
          <div className={`${styles.col} ${styles.mainCol}`}>
            {framesLists.length > 1 && (
              <button
                type="button"
                className={`${styles.control} ${styles.prevListButton} ${styles.dataBox}`}
                onClick={() => {
                  handlePrevListClick();
                }}
                data-testid="prevListButton"
              >
                <PlayCircleFilled />
              </button>
            )}
            <button
              type="button"
              className={`${styles.control} ${styles.prevFrameButton} ${styles.dataBox}`}
              onClick={() => {
                handlePrevFrameClick();
              }}
              ref={prevFrameButtonRef}
              data-testid="prevFrameButton"
            >
              <FastForward />
            </button>
            <button
              type="button"
              className={`${styles.control} ${styles.dataBox}`}
              onClick={() => {
                handleTogglePlayClick();
              }}
              ref={playPauseButtonRef}
              data-testid="playPauseButton"
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </button>
            <button
              type="button"
              className={`${styles.control} ${styles.dataBox}`}
              onClick={() => {
                handleNextFrameClick();
              }}
              ref={nextFrameButtonRef}
              data-testid="nextFrameButton"
            >
              <FastForward />
            </button>
            {framesLists.length > 1 && (
              <button
                type="button"
                className={`${styles.control} ${styles.dataBox}`}
                onClick={() => {
                  handleNextListClick();
                }}
                data-testid="nextListButton"
              >
                <PlayCircleFilled />
              </button>
            )}
          </div>
          <div className={`${styles.col} ${styles.lastCol}`}>
            {bottomRightContent}
            {showFrameDetails && !notFound && frameTimePosition === 'right' && <FrameTime />}
          </div>
        </div>
      </div>
    </div>
  );

  /* --------------------------------- Effects -------------------------------- */
  useLayoutEffect(() => {
    if (nextFrameTimeout.current) clearTimeout(nextFrameTimeout.current);
  }, [isPlaying, listIndex, frames, frameIsLoaded]);

  useLayoutEffect(() => {
    if (frameIsLoaded) {
      setShowFrameSpinner(false);
      if (showFrameSpinnerTimeout.current) clearTimeout(showFrameSpinnerTimeout.current);
      if (isPlaying) {
        nextFrameTimeout.current = setTimeout(() => setFrameIndex(forwardFrameIndex(frameIndex)), 1500);
      }
    } else {
      showFrameSpinnerTimeout.current = setTimeout(() => setShowFrameSpinner(true), 200);
    }
  }, [frameIsLoaded, isPlaying]); //eslint-disable-line

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [frames]); //eslint-disable-line

  useEffect(() => {
    if (onListIndexChange) {
      onListIndexChange(listIndex);
    }
  }, [listIndex]); //eslint-disable-line

  useEffect(() => {
    setFrameIndex(0);
  }, [frames]);

  useEffect(() => {
    setListIndex(0);
  }, [framesLists]);

  useEffect(() => {
    setFrameIndex(0);
    setListIndex(_listIndex);
  }, [_listIndex]);

  useEffect(() => {
    if (!frames.length) return;
    preloadImage(frames[forwardFrameIndex(frameIndex)]?.frameUrl);
    preloadImage(frames[backwardFrameIndex(frameIndex)]?.frameUrl);
  }, [frameIndex, frames]); //eslint-disable-line

  useEffect(() => {
    if (showFrameDetails && frames.length > 0 && frames[frameIndex]) {
      const dateString = frames[frameIndex].frameTime;

      setFrameTimeDetails({
        date: fromUtcToProjectTime(dateString, 'ISO_DATE').split(' ')[0],
        time: fromUtcToProjectTime(dateString, 'ISO_TIME'),
      });
    }
  }, [frameIndex, frames, showFrameDetails]); //eslint-disable-line

  useEffect(() => {
    setIsPlaying(!overlayContent);
  }, [overlayContent]);

  useEffect(() => {
    if (frames.length && frames[frameIndex]) {
      const { lat: latPrev, lon: lonPrev } = frames?.[backwardFrameIndex(frameIndex)];
      const { lat: latCurr, lon: lonCurr } = frames[frameIndex];
      const activeFrameCoordsPayload = {
        prev: { latPrev, lonPrev },
        curr: { latCurr, lonCurr },
      };

      setFrameIsLoaded(false);
      if (onActiveFrameCoordsChange) onActiveFrameCoordsChange(activeFrameCoordsPayload);
      if (onActiveFrameDetailsChange) onActiveFrameDetailsChange(frames[frameIndex]);
    }
  }, [frameIndex, listIndex, frames]); //eslint-disable-line

  return fullScreen ? (
    <Modal
      className={styles.fullScreenModal}
      open={fullScreen}
      onClose={handleFullScreenModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: styles.fullScreenModalBackdrop,
      }}
    >
      <Fade in={fullScreen}>
        <>
          <Slider />
        </>
      </Fade>
    </Modal>
  ) : (
    <Slider />
  );
};
