import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { LoginOverlayProps } from 'interfaces';
import styles from './LoginOverlay.module.scss';

export const LoginOverlay: React.FC<LoginOverlayProps> = ({
  headline,
  subhead,
  button = 'Log in to continue',
  loginUrl,
  headlineProps = {},
  overlayProps = {},
  subheadProps = {},
  mainAreaProps = {},
  buttonProps = {},
  circularProgressProps,
}) => {
  /* ---------------------------------- Hooks --------------------------------- */

  /* ---------------------------------- State --------------------------------- */
  const [isLoading, setLoading] = useState(false);

  /* -------------------------------- Variables ------------------------------- */
  overlayProps.className = `${styles.overlay} ${overlayProps.className || ''}`;
  headlineProps.className = `${styles.headline} ${headlineProps.className || ''}`;
  subheadProps.className = `${styles.subhead} ${subheadProps.className || ''}`;
  mainAreaProps.className = `${styles.mainArea} ${mainAreaProps.className || ''}`;
  buttonProps.className = `${styles.button} ${buttonProps.className || ''}`;

  /* -------------------------------- Functions ------------------------------- */
  const handleButtonClick = () => {
    setLoading(true);
    if (loginUrl) {
      window.location.assign(loginUrl);
    }
    if (buttonProps.onClick) {
      buttonProps.onClick();
    }
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <div {...overlayProps}>
      {headline && <h1 {...headlineProps}>{headline}</h1>}
      {subhead && <p {...subheadProps}>{subhead}</p>}
      <div {...mainAreaProps}>
        {isLoading ? (
          <CircularProgress {...circularProgressProps} data-testid="loginOverlaySpinner" />
        ) : (
          <Button
            variant="contained"
            color="primary"
            data-testid="loginOverlayButton"
            {...buttonProps}
            onClick={handleButtonClick}
          >
            {button}
          </Button>
        )}
      </div>
    </div>
  );
};
