import { useRef, useEffect } from 'react';
import { FrameCanvasProps } from 'interfaces';
import { BoundingBoxType } from 'enums';
import theme from 'theme';
import { useIntl } from '../../../hooks';
import { getDetectionTypes } from './getDetectionTypes';
import { getMetricTypesBoxes } from './getMetricTypesBoxes';

export const FrameCanvas: React.FC<FrameCanvasProps> = ({
  boundingBoxes,
  tags,
  imgRef,
  showCountInLegend,
  metricType,
}) => {
  const intl = useIntl('en');
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const detectionTypes = getDetectionTypes(intl);
  const metricTypesBoxes = getMetricTypesBoxes(tags);
  const visibleTypes = metricType ? metricTypesBoxes.get(metricType) : [];

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (!canvas || !context) return;

    const typesInFrame: Array<BoundingBoxType> = [];
    const fontSize = 20;

    canvas.width = imgRef?.current?.naturalWidth || 0;
    canvas.height = imgRef?.current?.naturalHeight || 0;

    if (!visibleTypes) return;
    context.font = `bold ${fontSize}px Arial`;
    boundingBoxes.forEach((box) => {
      if (!visibleTypes.includes(box.type)) return;
      context.save();
      const detectionTypeText = detectionTypes.get(box.type)?.text;
      const detectionTypeColor = detectionTypes.get(box.type)?.color;

      context.fillStyle = detectionTypeColor || '';
      context.globalAlpha = 0.4;
      context.fillRect(box.topLeftX, box.topLeftY, box.bottomRightX - box.topLeftX, box.bottomRightY - box.topLeftY);
      if (!typesInFrame.includes(box.type)) {
        const count = boundingBoxes.filter((boundingBox) => boundingBox.type === box.type).length;
        const legendTitle = showCountInLegend ? `${detectionTypeText} (${count})` : detectionTypeText || '';

        typesInFrame.push(box.type);
        context.fillRect(10, (typesInFrame.length + 2) * fontSize + 5, 15, 15);
        context.fillStyle = theme.colorWhite;
        context.lineWidth = 1.5;
        context.globalAlpha = 1;
        context.fillText(legendTitle, 30, fontSize * (typesInFrame.length + 3));
        context.strokeText(legendTitle, 30, fontSize * (typesInFrame.length + 3));
      }
      context.restore();
    });
  }, []); //eslint-disable-line

  return <canvas ref={canvasRef} />;
};
