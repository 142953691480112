import React from 'react';
import { CollapseButtonProps } from 'interfaces';
import { ReactComponent as Arrow } from 'assets/images/arrow-direction.svg';
import styles from './CollapseButton.module.scss';

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  type = 'horizontal',
  pointer = 'right',
  className = '',
  onClick,
}) => (
  <button type="button" className={`${styles.button} ${styles[type]} ${className}`} onClick={onClick}>
    <Arrow className={`${styles.icon} ${styles[pointer]}`} />
  </button>
);
