import { Box, Typography } from '@material-ui/core';
import { HeaderProps } from 'interfaces';
import { ReactComponent as Logo } from './Logo@3x.svg';
import styles from './Header.module.scss';

export const Header: React.FC<HeaderProps> = ({
  color = 'white',
  bgColor = 'black',
  icon = undefined,
  hasText = true,
  boldHeader,
  plainHeader,
}) => {
  return (
    <Box
      className={`${styles.boxHeader} ${bgColor === styles.black ? styles.blackBg : ''} ${
        color === 'white' ? styles.whiteColor : ''
      }`}
    >
      <div className={styles.icon}>{icon}</div>
      <Logo className={styles.axilionLogo} />
      {hasText && (
        <>
          <Typography variant="caption" className={styles.boldTextHeader}>
            {boldHeader}
          </Typography>
          <Typography variant="caption" className={styles.plainTextHeader}>
            {plainHeader}
          </Typography>
        </>
      )}
    </Box>
  );
};
