import React, { useState } from 'react';
import { ListMenu } from 'components';
import { TabbedMenusProps } from 'interfaces';
import styles from './TabbedMenus.module.scss';

export const TabbedMenus: React.FC<TabbedMenusProps> = ({
  tabs,
  menus,
  menuHeight,
  onTabSelect,
  onMenuSelect,
  initialTab = tabs[0],
}) => {
  /* ---------------------------------- Hooks --------------------------------- */
  /* ---------------------------------- State --------------------------------- */
  const [menuItems, setMenuItems] = useState(menus[initialTab.name]);

  /* -------------------------------- Functions ------------------------------- */
  const handleTabSelect = (tab: typeof menuItems[0]) => {
    setMenuItems(menus[tab.name]);
    if (onTabSelect) {
      onTabSelect(tab);
    }
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <>
      <ListMenu type="tabbed" items={tabs} initialItem={initialTab} onSelect={handleTabSelect} />
      <div className={styles.content} style={{ height: menuHeight }}>
        <ListMenu type="basic" items={menuItems} onSelect={onMenuSelect} />
      </div>
    </>
  );
};
