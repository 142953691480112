import React from 'react';
import { Button as MUIButton } from '@material-ui/core';
import { ButtonProps } from 'interfaces';
import styles from './Button.module.scss';

export const Button: React.FC<ButtonProps> = ({ model = 'default', ...muiProps }) => {
  const classes = [styles.button, muiProps.className];

  if (model === 'default') {
    muiProps.color = muiProps.color || 'primary';
    muiProps.variant = muiProps.variant || 'contained';
    muiProps.style = { borderColor: 'primary', ...muiProps.style };
    if (muiProps.fullWidth === undefined) muiProps.fullWidth = true;
  }

  if (model === 'borderless') {
    classes.push(styles.borderless);
  }

  muiProps.className = classes.join(' ');

  return <MUIButton {...muiProps} />;
};
