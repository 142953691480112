import { BoundingBoxType, MetricType } from '../../../enums';

export const getMetricTypesBoxes = (tags: Array<string>): Map<MetricType, Array<BoundingBoxType>> => {
  const metricTypesBoxes = new Map();

  metricTypesBoxes.set(MetricType.TOTAL_DENSITY, [
    BoundingBoxType.PERSON,
    BoundingBoxType.BICYCLE,
    BoundingBoxType.SCOOTER,
  ]);
  metricTypesBoxes.set(MetricType.PEDESTRIAN_DENSITY, [BoundingBoxType.PERSON]);
  metricTypesBoxes.set(MetricType.SCOOTER_DENSITY, [BoundingBoxType.SCOOTER]);
  metricTypesBoxes.set(MetricType.CYCLIST_DENSITY, [BoundingBoxType.BICYCLE]);
  metricTypesBoxes.set(MetricType.FACE_MASK_USAGE, [
    BoundingBoxType.FACE_MASK_USAGE,
    BoundingBoxType.NO_FACE_MASK_USAGE,
  ]);
  metricTypesBoxes.set(MetricType.PASSENGERS_DENSITY, [
    BoundingBoxType.PERSON,
    BoundingBoxType.BICYCLE,
    BoundingBoxType.SCOOTER,
  ]);
  if (tags.includes('activeCrosswalk')) {
    metricTypesBoxes.set(MetricType.ACTIVE_CROSSWALK, [
      BoundingBoxType.PERSON,
      BoundingBoxType.BICYCLE,
      BoundingBoxType.SCOOTER,
    ]);
  }
  if (tags.includes('activeCrosswalk') && tags.includes('green')) {
    metricTypesBoxes.set(MetricType.PEDESTRIANS_SIGNAL_PROGRESSION, [
      BoundingBoxType.PERSON,
      BoundingBoxType.BICYCLE,
      BoundingBoxType.SCOOTER,
    ]);
  }
  if (tags.includes('wildPedestrian')) {
    metricTypesBoxes.set(MetricType.JAYWALKERS, [
      BoundingBoxType.PERSON,
      BoundingBoxType.BICYCLE,
      BoundingBoxType.SCOOTER,
    ]);
  }
  if (tags.includes('refugeIsland')) {
    metricTypesBoxes.set(MetricType.RED_LIGHT, [BoundingBoxType.REFUGE_ISLAND]);
  }

  return metricTypesBoxes;
};
